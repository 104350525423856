/* Additional styling for each section */
section {
  padding: 10vw 0;
  text-align: center;
  width: 100%; /* Ensure the sections take the full viewport width */
}

#about, #portfolio, #contact {
  background-color: #f5e9e2;
  color: #4a4a4a;
}


