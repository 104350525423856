/* Portfolio Section */
#portfolio {
    padding-top: 7.5vw; /* Add top padding to prevent the title from being hidden by the navbar */
}

.project-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three equal columns */
    gap: 100px; /* Increase space between projects */
    padding: 0 5%; /* Side padding adjusts based on browser size */
    margin-top: 0vw;
}

.project {
    background-color: transparent;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; /* Full width of the grid cell */
}

.project img {
    width: 100%;
    height: auto;
    max-height: 30vw; /* Set maximum height relative to viewport width */
    aspect-ratio: 4/3; /* Keep aspect ratio consistent */
    object-fit: cover;
    background-color: #f5eae6;
    border: none;
    margin-bottom: 2vw; /* Increase space between rows */
}

#portfolio h2 {
    font-family: 'Playfair Display', serif;
    font-size: 4vw; /* Responsive font size based on viewport width */
    color: #4e4745;
    text-align: center;
    margin-bottom: 0vw;
    font-weight: bold;
}

.project h3 {
    margin: -1vw 0 0 0;
    font-size: 2vw; /* Responsive font size */
    color: #1a1a1a;
    text-decoration: none;
    text-align: center;
    width: 130%;
    background-color: transparent;
    font-family: 'Playfair Display', serif;
    font-weight: bold;
}

.project a {
    text-decoration: none;
    color: inherit;
}

.project a:hover {
    text-decoration: none;
    color: inherit;
}

.project-intro {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.8vw; /* Responsive font size */
    margin-bottom: 1vw;
    color: #07496e;
}

.underline-container {
    display: flex;
    justify-content: center;
    margin-bottom: 0vw;
}

.underline-image {
    width: 7.5vw;
    height: auto;
    position: relative;
    top: -1.5vw;
    left: 8vw;
    transform: translateX(-50%); /* Center the image horizontally */
}

/* Responsive Design */
@media (max-width: 768px) {

    #portfolio h2 {
        font-size: 6vw; /* Adjust title size for smaller screens */
        margin-top: 5vw; /* Add some space before the project intro */
    }

    .project h3 {
        font-size: 3.5vw; /* Responsive font size */
        font-weight: bold;
        width: 110%;
    }

    .project-intro {
        font-size: 3vw;
        margin-bottom: 3vw
    }
    
    .project-list {
        grid-template-columns: repeat(2, 1fr); /* Two projects per row */
        gap: 5vw; /* Adjust gap for smaller screens */
    }

    .project img {
        max-height: 40vw; /* Larger images on smaller screens */
    }

    .underline-image {
        left: 12vw;
        top: -4vw;
        width: 11vw;
    }
}

@media (max-width: 480px) {

    #portfolio h2 {
        font-size: 8vw;
        
    }
    
    .project-list {
        grid-template-columns: 1fr; /* One project per row */
        gap: 3vw; /* Adjust gap for very small screens */
    }

    .project h3 {
        font-size: 5vw; /* Responsive font size */
        font-weight: bold;
    }

    .project img {
        width: 80%;
    }

    .project {
        margin-bottom: 5vw; /* Add space between rows */
    }

    .underline-image {
        width: 17vw;
        top: -4.5vw;
        left: 17vw;
    }

    .project-intro {
        font-size: 4vw;
    }
}

/* Adjust layout for landscape orientation */
@media (orientation: landscape) and (max-height: 500px) {

    #portfolio h2 {
        margin-top: 1vw
    }


    .project-list {
        grid-template-columns: repeat(3, 1fr); /* Three projects per row in landscape mode */
    }

    .project img {
        max-height: 30vw; /* Larger images for landscape mode */
    }

    .project h3 {
        font-size: 2.5vw; /* Responsive font size */
        width: 100%;
        font-weight: bold;
    }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

    #portfolio h2 {
        font-size: 5vw;
    }

    .underline-image {
        width: 7vw;
        top: -1.5vw;
        left: 7.5vw;
    }

    .project h3 {
        font-size: 2.5vw; /* Responsive font size */
        width: 110%;
        font-weight: bold;
    }

    #portfolio {
        padding-top: 10vw; /* Add some padding at the top to adjust the scroll position */
    }

    /* Adjust the position of the project list */
    .project-list {
        margin-top: 2vw; /* Increase the margin at the top for better visibility */
        gap: 4vw; /* Keep a proportional gap between projects */

    }
}