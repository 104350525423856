.about-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8%; /* Reduce side padding to make the paragraph wider */
    max-width: 95vw; /* Increase the max width */
    margin: 0 auto;
    position: relative;
}

.about-text {
    flex: 1;
    max-width: 50vw; /* Increase the width of the text */
    text-align: center;
    position: relative;
    z-index: 1;
}

.about-text h2 {
    font-size: 3.5vw; /* Adjust the font size */
    color: #07496e;
    white-space: nowrap;
    font-family: 'Playfair Display', serif;
    font-weight: bold;
    margin-top: 1em; /* Lower the header */
    margin-bottom: 0.5em;
    position: relative;
    z-index: 1;
}

.about-text p {
    font-size: 1.3vw; /* Adjust font size */
    color: #4a4a4a;
    max-width: 50vw; /* Increase the width of the paragraph */
    margin: 0 auto;
    margin-bottom: 0.5em; /* Added margin to create space between paragraphs */
    font-family: 'Open Sans', sans-serif;
    text-align: justify;
    hyphens: none; /* Disable hyphenation */
    overflow-wrap: break-word; /* Ensure long words break properly */
    word-break: break-word; /* Break long words to avoid overflow */
}

.highlight-container {
    position: relative;
    display: inline-block;
}

.ellipse-background {
    position: absolute;
    width: 11.5vw; /* Adjust size */
    height: auto;
    top: 30%; /* Adjust position */
    left: 17%;
    transform: translateX(-50%);
    z-index: -1;
}

.about-image {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    position: relative;
    width: 50%; /* Adjust width */
    margin-top: 1.5em; /* Align the image with the title */
    margin-left: -10em; /* Add this to reduce space between the text and image */
}

.about-image img {
    width: 55%; /* Adjust image size */
    height: auto;
    aspect-ratio: 3 / 4;
    object-fit: cover;
    position: relative;
}


@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {

    .about-image {
        margin-right: 12vw;
    }

}

/* Responsive adjustments */
@media (max-width: 768px) {
    .about-container {
        align-items: center;
        padding: 4vw; /* Adjusted for smaller screens */
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        position: relative;

    }

    .about-text h2 {
        font-size: 6vw;
        text-align: center;
        margin: 0;
        margin-top: 1.5vw;
    }

    .about-text {
        text-align: center;
        max-width: 70vw; /* Adjusted for smaller screens */
        margin-bottom: 4vw; /* Adjusted for spacing */
    }

    .about-text p {
        font-size: 3vw; /* Adjusted for smaller screens */
        max-width: 100vw; /* Adjusted for responsiveness */
        margin-top: 1.2em; /* Increase margin between title and paragraph */
        text-align: justify;
        hyphens: auto;
    }

    .about-image {
        width: 100%;
    }

    .about-image img {
        max-width: 100%; /* Adjusted for smaller screens */
        object-fit: cover; /* Ensure the image covers the container */
    }

    .ellipse-background {
        width: 20vw; /* Adjusted for smaller screens */
        top: 0%;
        left: 17%;
        transform: translateX(-50%);
    }
}

@media (max-width: 480px) {
    .about-container {
        align-items: center;
        padding: 4vw; /* Adjusted for smaller screens */
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        margin-top: 2vw; 
    }

    .about-text {
        max-width: 70vw; /* Adjusted for smaller screens */
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .about-text h2 {
        font-size: 8vw;
        text-align: center;
        margin: 2;
        margin-top: 4vw;
    }

    .about-text p {
        font-size: 4vw; /* Adjusted for smaller screens */
        padding: 0 0vw; /* Make the paragraph wider */
        max-width: 90vw; /* Adjusted for responsiveness */
        margin-top: 1.5em; /* Increase margin between title and paragraph */
    }

    .about-image {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        position: relative;
        width: 130%;
    }

    .about-image img {
        max-width: 120%; /* Adjusted for smaller screens */
        object-fit: cover; /* Ensure the image covers the container */
    }

    .ellipse-background {
        width: 27vw; /* Adjusted for smaller screens */
        top: 5%;
        left: 16%;
        transform: translateX(-50%);
    }
}

@media (orientation: landscape) and (max-width: 1024px) {

    .about-container {
        margin-top: -5rem;
    }

    .about-text h2 {
        font-size: 3rem; /* Adjust font size */
    }

    .about-text p {
        font-size: 1rem; /* Adjust font size */
        max-width: 80vw; /* Increase max-width */
    }

    .about-image {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        position: relative;
        width: 65%;
    }

    .about-image img {
        max-width: 65%; /* Adjusted for smaller screens */
        object-fit: cover; /* Ensure the image covers the container */
    }

    .ellipse-background {
        width: 15vw; /* Adjusted for smaller screens */
        top: 31%;
        left: 17%;
        transform: translateX(-50%);
    }

}


@media (orientation: landscape) and (max-width: 812px) {
    

    .about-text h2 {
        font-size: 3rem; /* Adjust font size */
        margin-top: 2em;
    }

    .about-text p {
        font-size: 1.3rem; /* Adjust font size */
        max-width: 80vw; /* Increase max-width */
    }

    .about-image {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        position: relative;
        width: 65%;
    }

    .about-image img {
        max-width: 65%; /* Adjusted for smaller screens */
        object-fit: cover; /* Ensure the image covers the container */
    }

    .ellipse-background {
        width: 21vw; /* Adjusted for smaller screens */
        top: 58%;
        left: 17%;
        transform: translateX(-50%);
    }

}