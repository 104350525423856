@import url('https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

html, body {
    margin: 0;
    padding: 0;
    background-color: #f5eae6;
    overflow-x: hidden;
    width: 100%;
}

#home {
    background-color: #f5eae6;
    color: #4a4a4a;
    text-align: center;
    padding: 12vw 0;
    font-family: 'Open Sans', sans-serif;
    position: relative;
    width: 100%;
    margin-top: 5.2vh; /* Add margin to move it lower */

}

#home .container {
    display: inline-block;
    text-align: center;
    position: relative;
    padding: 0 10%; /* Side padding adjusts based on browser size */
    max-width: 90vw; /* Increased side margins */
}

.text-ellipse-container {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center the content */
    align-items: center;
    height: 1.2em; /* Set a fixed height */
    font-size: 4.5vw; /* Scaled down the size slightly */
}


.ellipse-container {
    position: absolute;
    width: 120%;
    height: 150%;
    top: -97%;
    left: -58.9%;
    pointer-events: none;
    z-index: 1;
    overflow: visible;
}

.ellipse {
    position: absolute;
    width: 100%;
    height: 160%;
    stroke: black;
    stroke-width: 1;
    fill: none;
    stroke-dasharray: 10em;
    stroke-dashoffset: -10em;
    visibility: hidden;
    transform: rotate(-17deg);
    transform-origin: center;
}

#home h2 {
    padding: 0;
    display: inline-flex;
    font-size: 0.85em; /* Slightly smaller font size */
    white-space: nowrap;
    margin: 0;
    min-height: 1.3em;
    line-height: 1.3em;
    position: relative;
    word-spacing: 0.15em;
    font-family: 'Alex Brush', cursive;
    font-weight: bold;
    overflow: hidden; /* Prevent second line from moving during typing */
}

#home p {
    font-size: 1.8vw; /* Adjusted font size for paragraph */
    color: #153828;
    display: block;
    margin-top: 1em; /* Increased margin between title and paragraph */
}

.italic {
    color: #07496e;
    font-style: italic;
    font-size: 1.2em;
    margin-top: 0.1em; /* Adjust the margin to position it correctly */
    margin-right: 0.1em;
}

.highlight {
    color: black;
    position: relative;
    display: inline-block;
    font-family: 'Playfair Display', serif;
}

.draw {
    animation: drawEllipse 1s ease-out forwards;
}

.erase {
    animation: eraseEllipse 1s ease-out forwards;
}

@keyframes drawEllipse {
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes eraseEllipse {
    from {
        stroke-dashoffset: 0;
    }
    to {
        stroke-dashoffset: 10em;
    }
}



/* Mobile adjustments */
@media (max-width: 768px) {

    #home {
        overflow: visible;
        margin-top: 0vh; /* Add margin to move it lower */
    }

    #home .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 10%; /* Side padding adjusts based on browser size */
        max-width: 100vw; /* Increased side margins */
    }

    .text-ellipse-container {
        font-size: 6vw; /* Adjusted size for mobile */
        transform: translateX(4%); /* Shifted slightly to the left */

    }

    .ellipse-container {
        position: absolute;
        width: 120%;
        height: 150%;
        top: -60%;
        left: -40%;
        pointer-events: none;
        z-index: 1;
        overflow: visible;
    }
    
    .ellipse {
        position: absolute;
        width: 100%;
        height: 150%;
        stroke: black;
        stroke-width: 1;
        fill: none;
        stroke-dasharray: 10em;
        stroke-dashoffset: 10em;
        visibility: visible;
        transform: rotate(-17deg) scale(1.8);
        transform-origin: center;
    }

    .draw {
        animation: drawEllipseMobile 1s ease-out forwards;
    }

    .erase {
        animation: eraseEllipseMobile 1s ease-out forwards;
    }

    @keyframes drawEllipseMobile {
        to {
            stroke-dashoffset: 20em;
        }
    }

    @keyframes eraseEllipseMobile {
        from {
            stroke-dashoffset: 0;
        }
        to {
            stroke-dashoffset: 10em;
        }
    }

    #home h2 {
        font-size: 1.5em;
        white-space: nowrap;
        margin: 0;
        min-height: 1.3em; /* Reserve space equal to the font size */
        line-height: 1.3em; /* Ensure consistent line height */
        position: relative;
        word-spacing: 0.15em;
        font-family: 'Alex Brush', cursive;
        font-weight: bold;
        overflow: hidden; /* Prevent second line from moving during typing */
    }

    #home p {
        font-size: 1em;
        margin-top: 1.5em; /* Increase margin between title and paragraph */
    }
}

@media (min-width: 768px) and (max-width: 1024px) {

    #home {
        overflow: visible;
        margin-top: 0vh; /* Add margin to move it lower */

    }

    #home .container {
        position: relative;
        overflow: visible;
        max-width: 90vw; /* Adjust side margins for mobile */
    }

    .text-ellipse-container {
        font-size: 6vw; /* Adjusted size for mobile */
    }

    .ellipse-container {
        position: absolute;
        width: 120%;
        height: 150%;
        top: -55%;
        left: -41%;
        pointer-events: none;
        z-index: 1;
        overflow: visible;
    }
    
    .ellipse {
        position: absolute;
        width: 102%;
        height: 140%;
        stroke: black;
        stroke-width: 1;
        fill: none;
        stroke-dasharray: 10em;
        stroke-dashoffset: 10em;
        visibility: visible;
        transform: rotate(-17deg) scale(1.3);
        transform-origin: center;
    }

    .draw {
        animation: drawEllipseMobile 1s ease-out forwards;
    }

    .erase {
        animation: eraseEllipseMobile 1s ease-out forwards;
    }

    @keyframes drawEllipseMobile {
        to {
            stroke-dashoffset: 20em;
        }
    }

    @keyframes eraseEllipseMobile {
        from {
            stroke-dashoffset: 0;
        }
        to {
            stroke-dashoffset: 10em;
        }
    }

    #home h2 {
        font-size: 1.1em;
        font-family: 'Alex Brush', cursive;
    }

    #home p {
        font-size: 1.2em;
    }
}

@media (orientation: landscape) and (max-height: 500px) {

    #home {
        margin-top: 0vh; /* Add margin to move it lower */
    }

    #home h2 {
        font-size: 1.1em; /* Reduce font size for landscape mobile */
    }

    .ellipse {
        transform: rotate(-17deg) scale(1.3);
    }
    
}

/* Adjustments for iPad Landscape Mode */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

    #home {
        margin-top: 0vh; /* Add margin to move it lower */
    }

    .text-ellipse-container {
        font-size: 4.7vw; /* Adjusted size for mobile */
    }

    #home h2 {
        font-size: 1.12em; /* Reduce font size for landscape mobile */
    }
    
    #home p {
        font-size: 1.4em;
    }

    .ellipse {
        width: 80%;
        height: 95%;
    }

}