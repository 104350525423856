.custom-font-button-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap; /* Ensure buttons stay in one line */
  min-width: 200px;
  gap: 0.5rem; /* Adjust the gap between buttons */
}

.custom-font-button {
  font-family: 'Playfair Display', serif;
  font-size: calc(1rem + 0.5vw); /* Adjust font size based on browser width */
  font-weight: bold;
  color: #fff;
  margin: 0 0.5rem; /* Reduce margin to bring buttons closer together */
  background: none;
  border: none;
  padding: calc(0.25rem + 0.2vw) 0; /* Adjust padding for scaling */
  cursor: pointer;
  outline: none;
  transition: text-decoration 0.2s;
  min-width: 80px; /* Adjust minimum width */
  text-align: center;
}

.custom-font-button:hover {
  text-decoration: underline;
}

.fixed-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(50px + 1vw); /* Adjust height based on browser width */
  background-color: #4e4745;
  z-index: 1000; 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: calc(0.5rem + 0.5vw) 1rem; /* Adjust padding for scaling */
}

section {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
  box-sizing: border-box;
  width: 100vw; /* Ensure the sections take the full viewport width */
}

#home, #about, #portfolio, #contact {
  background-color: #f0f0f0;
  color: #333;
  padding: 2rem;
}

@media (max-width: 768px) {
  .fixed-nav {
    flex-direction: row;
    height: auto;
    padding: calc(0.5rem + 0.5vw); /* Adjust padding for smaller screens */
  }

  .custom-font-button-container {
    justify-content: space-around; /* Adjust alignment to center buttons */
    gap: 0.25rem; /* Reduce gap between buttons for mobile */
  }

  .custom-font-button {
    font-size: calc(0.875rem + 0.5vw); /* Adjust font size for smaller screens */
    margin: 0 0.25rem; /* Further reduce margin */
  }

  section {
    padding: calc(3rem + 1vw) 1rem; /* Increase padding for better spacing on mobile */
    width: 100vw; /* Ensure full width on mobile */
  }
}

