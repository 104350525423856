.contact-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 10vw; /* Equal padding on both sides */
    box-sizing: border-box;
    position: relative;
}

.container-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 80vw; /* Adjusted to center content better */
    flex-wrap: wrap;
    box-sizing: border-box;
}

.left-container, .right-container {
    display: flex;
    flex-direction: column;
    justify-content: center;    
    width: 45%;
    padding: 2vw; /* Adjusted padding for responsiveness */
    box-sizing: border-box;
    text-align: center;
}


.top-content {
    margin-top: -2vw;
    margin-bottom: 2vw; /* Adjusted for responsiveness */
    position: relative;
    text-align: center;
}

.about-heading {
    margin: 0;
    padding: 0;
    line-height: 1.5;
}

.custom-line {
    border: 0.15vw solid #bd8f53; /* Adjusted for responsiveness */
    width: 120%;
    max-width: none;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 100%;
}

.contact-items {
    display: flex;
    flex-direction: column;
    gap: 2vw; /* Adjusted gap for responsiveness */
}

.contact-item {
    display: flex;
    align-items: center;
    text-align: center;
    gap: 2vw; /* Adjusted gap for responsiveness */
    font-size: 1.5vw; /* Responsive font size for contact info */
    flex-grow: 1; /* Allow text to take up available space */
    font-family: 'Playfair Display', sans-serif;
}

.contact-item .icon-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #07496e;
    border-radius: 60%;
    width: 5vw; /* Adjusted for responsiveness */
    height: 5vw; /* Adjusted for responsiveness */
    padding: 1vw; /* Adjusted for responsiveness */
    flex-shrink: 0; /* Prevent icon from shrinking */
}

.contact-item .icon {
    color: #e0f7fa;
    font-size: 3vw; /* Adjusted for responsiveness */
}

.contact-item div {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align the text to the left */
    color: #07496e;
}


.contact-wrapper h2 {
    font-size: 3.5vw; /* Adjusted for responsiveness */
    color: #4e4745;
    font-family: 'Playfair Display', sans-serif;
    font-weight: bold;

}

.hidden-link {
    text-decoration: none; /* Remove underline */
    color: inherit; /* Inherit color from parent element */
    cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
}

.hidden-link:hover {
    text-decoration: none; /* Ensure no underline on hover */
    color: inherit; /* Keep color consistent on hover */
}

/* Responsive Design */
@media screen and (max-width: 768px) {
    .contact-wrapper {
        padding: 0; /* Adjusted padding for smaller screens */
    }

    .container-wrapper {
        flex-direction: center;
        align-items: center;
    }

    .custom-line {
        width: 80vw; /* Adjusted width for smaller screens */
    }

    .left-container, .right-container {
        width: 90vw; /* Adjusted for smaller screens */
        text-align: center;
        padding: 4vw; /* Adjusted padding for smaller screens */
    }

    .contact-items {
        align-items: center;
        gap: 5vw; /* Adjusted gap for responsiveness */
        margin-top: -5vw;
    }

    .contact-item {
        flex-direction: column;
        align-items: center;
        gap: 2vw; /* Adjusted gap for smaller screens */
        font-size: 3vw; /* Responsive font size for contact info */
    }

    .contact-item .icon-circle {
        width: 8.5vw; /* Adjusted for smaller screens */
        height: 8.5vw; /* Adjusted for smaller screens */
    }

    .contact-item .icon {
        font-size: 5.5vw; /* Adjusted for smaller screens */
    }

    .contact-item div {
        align-items: center; /* Align the text to the left */
    }

    .about-heading {
        line-height: 1.5;
    }

    .contact-wrapper h2 {
        font-size: 7vw; /* Adjusted for smaller screens */
    }
}

@media screen and (max-width: 480px) {
    .contact-wrapper {
        padding: 0; /* Adjusted padding for smaller screens */
        margin-top: -15vw;
    }

    .custom-line {
        width: 80vw; /* Adjusted width for smaller screens */
    }

    .left-container, .right-container {
        width: 90vw; /* Adjusted for smaller screens */
        text-align: center;
        padding: 4vw; /* Adjusted padding for smaller screens */
    }

    .contact-items {
        align-items: center;
        gap: 5vw; /* Adjusted gap for responsiveness */
    }

    .contact-item {
        flex-direction: column;
        align-items: center;
        gap: 2vw; /* Adjusted gap for smaller screens */
        font-size: 4.5vw; /* Responsive font size for contact info */
    }

    .contact-item .icon-circle {
        width: 12vw; /* Adjusted for smaller screens */
        height: 12vw; /* Adjusted for smaller screens */
    }

    .contact-item .icon {
        font-size: 7vw; /* Adjusted for smaller screens */
    }

    .about-heading {
        line-height: 1.5;
    }

    .contact-wrapper h2 {
        font-size: 9vw; /* Adjusted for smaller screens */
    }
}

/* Adjust layout for landscape orientation */
@media (orientation: landscape) and (max-height: 500px) {
    .contact-wrapper {
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0 vw; /* Equal padding on both sides */
        box-sizing: border-box;
        position: relative;
        margin-right: 4vw;
    }

    .container-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 80vw; /* Adjusted to center content better */
        flex-wrap: wrap;
        box-sizing: border-box;
    }

    .left-container, .right-container {
        width: 48%; /* Ensure containers are side by side */
        text-align: center;
        padding: 2vw; /* Adjust padding for landscape */
    }

    .custom-line {
        width: 120%; /* Keep custom line consistent with web version */
    }

    .contact-items {
        display: flex;
        flex-direction: column;
        align-items: flex-start; /* Align items to the start of the container */
        gap: 2vw; /* Adjust gap for landscape */
        margin: 0 auto; /* Center the items vertically */
    }

    .contact-item {
        display: flex;
        flex-direction: row; /* Keep items in a row */
        align-items: center; /* Ensure icons and text are vertically centered */
        gap: 1vw; /* Adjust gap between icon and text */
        font-size: 2.2vw; /* Adjust font size */
    }

    .contact-item .icon-circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 6vw; /* Adjust icon circle size */
        height: 6vw; /* Adjust icon circle size */
    }

    .contact-item div {
        display: flex;
        flex-direction: column;
        align-items: flex-start; /* Align the text to the left */
        color: #07496e;
    }

    .contact-item .icon {
        font-size: 3.5vw; /* Adjust icon size */
    }

    .contact-wrapper h2 {
        font-size: 4.4vw; /* Adjusted for smaller screens */
    }
}


/* Adjust layout for landscape orientation */
@media only screen and (orientation: landscape) and (min-width: 768px) and (max-width: 1024px) {
    

    .left-container, .right-container {
        width: 45%; /* Ensure containers are side by side */
        text-align: center;
        padding: 0vw; /* Adjust padding for landscape */
    }

    .contact-wrapper h2 {
        font-size: 4.2vw; /* Adjust the font size as needed for the iPad landscape mode */
    }

    .contact-item {
        font-size: 2vw; /* Adjust font size for contact items */
    }
}

