.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #f5e9e2;
    padding: 1.5em; /* Reduced padding for smaller side margins */
    border-radius: 0.5em;
    width: 90vw; /* Reduced width for smaller margins */
    max-width: 70em;
    text-align: left;
    position: relative;
    max-height: 80vh;
    overflow-y: auto;
    box-sizing: border-box;
}

.modal-content img {
    max-width: 100%;
    margin-top: 1em;
}

.link-button {
    display: inline-block;
    margin: 1.5em auto 0 auto;
    padding: 0.5em 1em; /* Adjusted padding for a smaller button */
    background-color: #333;
    color: #fff;
    font-size: 0.9em;
    border-radius: 5px;
    text-align: left;
    text-decoration: none;
}

.link-button:hover {
    background-color: #555;
}

.close-button {
    position: absolute;
    top: 0em;
    right: 0.5em;
    background-color: transparent;
    border: none;
    font-size: 2.5em;
    cursor: pointer;
}

.small-cover-photo {
    width: 20em;
    height: auto;
    display: block;
    margin: 0 auto 1em auto;
}



.modal-content h2 {
    font-family: 'Playfair Display', serif;
    font-size: 2em; /* Responsive font size based on viewport width */
    color: #4e4745;
    text-align: center;
    margin-bottom: -0.5vw;
    font-weight: bold;
}


.section-title {
    font-size: 1.2em;
    margin-top: 1em;
    margin-bottom: 0.3em;
    padding-left: 10%;
    padding-right: 10%;
}

.separator {
    width: 80%;
    height: 1px;
    background-color: rgba(51, 51, 51, 0.3);
    margin: 0 auto 1em auto;
    padding: 0 1em;
}

/* Add a specific class for the last separator to adjust margin */
.last-separator {
    margin-top: 1em; /* Increased space above the last separator */
}


.description-text, 
.key-takeaways-text {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center images horizontally */
    align-items: center; /* Center images vertically if needed */
    font-size: 0.9em;
    margin: 0 auto;
    color: #4a4a4a;
    width: 80%; /* Bounded within the same container */
    text-align: left; /* Align text to the left */
    padding: 0;
}


@media (max-width: 768px) {
    .small-cover-photo {
        margin-top: 2em;
        margin-bottom: 1em;
        width: 12em;
    }

    .modal-content h2 {
        width: 100%;
        padding-left: 10%;
        padding-right: 10%;
        margin: 0 auto 1em auto;
        font-size: 1.5em;
        hyphens: none;
    }

    .close-button {
        top: 1vw;
        right: -4vw;
        font-size: 2em;
    }

    .description-text img, 
    .key-takeaways-text img {
        max-width: 100%;
        height: auto; /* Allow the height to adjust automatically */
        object-fit: contain; /* Preserve the aspect ratio */
    }
    

    .modal-content {
        overflow-x: hidden;
        hyphens: auto;
        padding-left: 5%;  /* Reduce left padding */
        padding-right: 5%; /* Reduce right padding */
    }

    body.modal-open {
        overflow-x: hidden;
    }
}

@media (orientation: landscape) and (max-height: 500px) {

    .close-button {
        top: 1vw;
        right: 0vw;
        font-size: 2em;
    }

    .modal-content {
        overflow-x: hidden;
        hyphens: none;
    }

}


@media (min-width: 768px) and (max-width: 1024px) {

    .close-button {
        top: 1vw;
        right: 0vw;
        font-size: 2.5em;
    }

}

* {
    box-sizing: border-box;
}